import "./contactenos.css";
import Footer from "../footer/Footer";
import config from "../../helpers/config";
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useEffect,useState} from 'react';

const initInputs={
    nombre:"",
    email:"",
    telefono:"",
    tema:"",
    mensaje:""
}

export const Contactenos = ({idReferencia,setIdReferencia}) => {
    const [inputs,setInputs] = useState(initInputs);
    useEffect(()=>{
        if(idReferencia !== "" && document.getElementById(idReferencia)){
            let top = document.getElementById(idReferencia).offsetTop;
            window.scroll(0,top-100);
        }
        else{
            window.scroll(0,0);
        }
    },[idReferencia])

    const handleSubmit =(e)=>{
        e.preventDefault();
        let mensaje = `
                El usuario <strong>${inputs.nombre}</strong> ha solitado informacion sobre el tema <strong>${inputs.tema}</strong>,
                <br/><br/>
                el usuario ha proporcionado los siguientes datos para contactarlo:
                <br/><br/>
                <strong>E-mail:</strong> ${inputs.email}.
                <br/><br/>
                <strong>Telefono:</strong> ${inputs.telefono}.
                <br/><br/>
                Ademas a dejado el siguiente mensaje para mas informacion:
                <br/><br/>
                [${inputs.mensaje}]
            `
            fetch(`${config.host}/enviarCorreo`,{
                method:"POST",
                body: JSON.stringify({mensaje:mensaje,subject:`Solicitud de informacion ${inputs.nombre}`}),
                headers:{
                    "Content-Type":"application/json"
                }
            })
           .then(res=>{
                alert("Mensaje enviado con exito.");
                setInputs(initInputs);
           })
    }

  return (
    <div className='cont-contactenos'>
        <div>
            <h1>Contáctenos</h1>
        </div>

        <div className='cont-contactar'>
            <div>
                <h4>Contactar</h4>
                <p>info@sintesiscloud.com</p>
                <div className='cont-iconos'>
                    <a href="https://www.facebook.com/Sintesis-Cloud-102217549173818/?ref=pages_you_manage"><FontAwesomeIcon icon={faFacebook} /></a>
                    <a href="https://www.linkedin.com/company/s%C3%ADntesis-cloud-sas/"><FontAwesomeIcon icon={faLinkedin} /></a>
                    <a href="https://www.instagram.com/sintesis_cloud/"><FontAwesomeIcon icon={faInstagram} /></a>
                </div>
            </div>

            <div className='mapa'>
                <iframe title="mapa-sintesis-cloud" src="https://www.google.com/maps/d/embed?mid=1o5e-mxdUHudNQBUoRAjcCWXeiX4Yacqo&ehbc=2E312F"></iframe>
            </div>
        </div>

        <div className='cont-form-contac'>
            <h2>Necesitas información?</h2>
            <p>Solo escríbenos.</p>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nombre</label>
                    <input required value={inputs.nombre} onChange={(e)=>setInputs({...inputs,...{[e.target.name]:e.target.value}})} name='nombre' type="text"/>
                </div>
                <div>
                    <label>Teléfono</label>
                    <input required value={inputs.telefono} onChange={(e)=>setInputs({...inputs,...{[e.target.name]:e.target.value}})} name='telefono' type="text"/>
                </div>
                <div>
                    <label>E-mail</label>
                    <input required value={inputs.email} onChange={(e)=>setInputs({...inputs,...{[e.target.name]:e.target.value}})} name='email' type="text"/>
                </div>
                <div>
                    <label>Tema</label>
                    <input required value={inputs.tema} onChange={(e)=>setInputs({...inputs,...{[e.target.name]:e.target.value}})} name='tema' type="text"/>
                </div>
                <div>
                    <label>Mensaje</label>
                    <textarea required value={inputs.mensaje} onChange={(e)=>setInputs({...inputs,...{[e.target.name]:e.target.value}})} name="mensaje" ></textarea>
                </div>
                <div>
                    <button type="submit">Enviar</button>
                </div>
            </form>
        </div>
        <Footer setIdReferencia={setIdReferencia}/>
    </div>
  )
}
