import "./servicios.css"
import img_1 from "../../img/img_services-1.png"
import cliente1 from "../../img/cliente1.webp"
import cliente2 from "../../img/cliente2.jpg"
import cliente3 from "../../img/cliente3.webp"
import cliente4 from "../../img/cliente4.webp"
import cliente5 from "../../img/cliente5.webp"
import cliente6 from "../../img/cliente6.webp"
import ServiciosInfo from "../serviciosInfo/ServiciosInfo"
import TarjetasClientes from "../tarjetasClientes/TarjetasClientes"
import Footer from "../footer/Footer";
import {useEffect,useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmarkCircle,faArrowLeftLong,faArrowRightLong} from "@fortawesome/free-solid-svg-icons";

const imagenes = [
    {
        id:1,
        img:cliente1,
        texto:"Contabilidad, inventario y financiación."
    },
    {
        id:2,
        img:cliente2, 
        texto:"Contabilidad, activos fijos e inventarios."
    },
    {
        id:3,
        img:cliente3, 
        texto:"Sistema de créditos, CRM y contabilidad."
    },
    {
        id:4,
        img:cliente4, 
        texto:"Punto de venta, facturación electrónica, contabilidad."
    },
    {
        id:5,
        img:cliente5, 
        texto:"Contabilidad y administración."
    },
    {
        id:6,
        img:cliente6, 
        texto:"Contabilidad, inventario, punto de venta."
    }
]

const initImgActiva ={id:0,img:"",texto:""};

const Servicios = ({idReferencia,setIdReferencia}) => {
    const [imgActiva,setImgActiva] = useState(initImgActiva);
    
    useEffect(()=>{
        if(idReferencia !== "" && document.getElementById(idReferencia)){
            let top = document.getElementById(idReferencia).offsetTop;
            window.scroll(0,top-100);
        }
        else{
            window.scroll(0,0);
        }
    },[idReferencia])

    const handleImg =(tipo)=>{
        if(tipo === "left" && (imgActiva.id - 1)>0){
            setImgActiva(imagenes[imgActiva.id - 2]);
        }
        else if(tipo === "left" && (imgActiva.id - 1)===0){
            setImgActiva(imagenes[5]);
        }
        else if(tipo === "right" && (imgActiva.id)<imagenes.length){
            setImgActiva(imagenes[imgActiva.id]);
        }
        else if(tipo === "right" && (imgActiva.id)===imagenes.length){
            setImgActiva(imagenes[0]);
        }
    }

  return (
    <div className="cont-pag-servicios">
        <div>
            <img src={img_1} alt="img_1"/>
        </div>
        <div>
            <p>Ofrecemos una amplia variedad de sistemas, desde puntos de venta de pequeños negocios hasta el manejo completo de la mediana empresa.</p>
            <p>La seguridad es nuestra prioridad número uno. Nuestros sitios son seguros y altamente disponibles. Jamás escatimamos en nada.</p>
            <p>Su satisfacción es nuestra máxima prioridad.</p>
         </div>
        <ServiciosInfo setIdReferencia={setIdReferencia} orientacion="left" id="id_contabilidad" titulo="Contabilidad" texto="Información a tiempo, en eso se resume la finalidad de nuestro sistema contable. Una variedad de informes que satisfacen la necesidad del contador de cualquier empresa. Fácil manejo, todos los informes se exportan a PDF y/o Excel."/>
        <ServiciosInfo setIdReferencia={setIdReferencia} orientacion="right" id="id_inventario" titulo="Inventario"  texto="Podrás tener inventarios en tiempo real, compras, vetas, ajustes, traslados entre multiples bodegas, artículos seriados, artículos formulados."/>
        <ServiciosInfo setIdReferencia={setIdReferencia} orientacion="left"  id="id_gestionCartera" titulo="Gestion de cartera"  texto="Cartera por edades, gestión de cartera, registro de novedades, registro de bitácora de clientes, informes de cartera, cartera proyectada, pagos, etc.."/>
        <ServiciosInfo setIdReferencia={setIdReferencia} orientacion="right" id="id_facturaElect" titulo="Facturación electrónica"  texto="Ya no más papeles, nuestro sistema envía las facturas a los clientes directamente a su correo y a la dirección de impuestos y aduanas nacionales DIAN."/>
        <ServiciosInfo setIdReferencia={setIdReferencia} orientacion="left"  id="id_nominaElect" titulo="Nomina electrónica"  texto="¿Cuántos empleados tienes? eso ya no importa, con nuestra nómina de manera muy fácil e intuitiva en un solo paso, tendrás la liquidación y la contabilización de todos tus empleados y sin más y cuando lo necesites enviaras a DIAN."/>
        <ServiciosInfo setIdReferencia={setIdReferencia} orientacion="right" id="id_financiacion" titulo="Financiación"  texto="¿Necesitas financiar?, nuestro sistema te ofrece un abanico de oportunidades al momento de financiar tus productos y/o servicios financieros. Cálculos a futuro, deuda presente y mucho pero mucho más."/>

        <div id="clientes" className="cont-clientes">
            <div>
                <h1>Clientes</h1>
                <p>Nuestros clientes en Colombia</p>
            </div>
            <div>
                <TarjetasClientes setImgActiva={setImgActiva} objectImg={imagenes[0]} img={cliente1} title="Concesionarios de Motocicletas" texto="Contabilidad, inventario y financiación." />
                <TarjetasClientes setImgActiva={setImgActiva} objectImg={imagenes[1]} img={cliente2} title="Empresas de contadores" texto="Contabilidad, activos fijos e inventarios." />
                <TarjetasClientes setImgActiva={setImgActiva} objectImg={imagenes[2]} img={cliente3} title="Empresas financieras" texto="Sistema de créditos, CRM y contabilidad." />
                <TarjetasClientes setImgActiva={setImgActiva} objectImg={imagenes[3]} img={cliente4} title="Ferreterías" texto="Punto de venta, facturación electrónica, contabilidad." />
                <TarjetasClientes setImgActiva={setImgActiva} objectImg={imagenes[4]} img={cliente5} title="Centros comerciales" texto="Contabilidad y administración." />
                <TarjetasClientes setImgActiva={setImgActiva} objectImg={imagenes[5]} img={cliente6} title="Ganaderías" texto="Contabilidad, inventario, punto de venta." />
            </div>
        </div>

        {
            imgActiva.id !==0 &&
            <div className="modal-clientes">
                <div>
                    <div>{imgActiva.id}/6</div>
                    <div onClick={()=>setImgActiva(initImgActiva)}>
                        <FontAwesomeIcon icon={faXmarkCircle}/>
                    </div>
                </div>
                <div>
                    <button onClick={()=>handleImg("left")}><FontAwesomeIcon icon={faArrowLeftLong}/></button>
                    <div className={imgActiva.id===2 ? "imgActiva":""} ><img src={imagenes[1].img} alt="img_activa"/></div>
                    <div className={imgActiva.id===4 ? "imgActiva":""} ><img src={imagenes[3].img} alt="img_activa"/></div>
                    <div className={imgActiva.id===6 ? "imgActiva":""} ><img src={imagenes[5].img} alt="img_activa"/></div>
                    <div className={imgActiva.id===1 ? "imgActiva":""} ><img src={imagenes[0].img} alt="img_activa"/></div>
                    <div className={imgActiva.id===3 ? "imgActiva":""} ><img src={imagenes[2].img} alt="img_activa"/></div>
                    <div className={imgActiva.id===5 ? "imgActiva":""} ><img src={imagenes[4].img} alt="img_activa"/></div>
                    <button onClick={()=>handleImg("right")}><FontAwesomeIcon icon={faArrowRightLong}/></button>
                </div>
                <div>
                    <p>{imgActiva.texto}</p>
                </div>
            </div>
        }

        <Footer setIdReferencia={setIdReferencia}/>
        
    </div>
  )
}

export default Servicios