import "./footer.css"
import React from 'react'
import img_sintesis_gris from "../../img/imagen_sintesis_cloud_gris.webp";
import {faFacebook,faLinkedin,faInstagram} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from "react-router-dom";

const Footer = ({setIdReferencia}) => {
  return (
    <footer>
        <div>
            <img src={img_sintesis_gris} alt="img_sintesis_gris"/>
        </div>
        <div>
            <h4>Oficina central</h4>
            <p>Cartagena - Colombia</p>
            <div>
                <a href="https://www.facebook.com/Sintesis-Cloud-102217549173818/?ref=pages_you_manage"><FontAwesomeIcon icon={faFacebook} /></a>
                <a href="https://www.linkedin.com/company/s%C3%ADntesis-cloud-sas/"><FontAwesomeIcon icon={faLinkedin} /></a>
                <a href="https://www.instagram.com/sintesis_cloud/"><FontAwesomeIcon icon={faInstagram} /></a>
            </div>
        </div>
        <div>
            <h4>Contacto</h4>
            <p>info@sintesiscloud.com</p>
            <button>
                <NavLink to="/inicio" onClick={()=>setIdReferencia("solicitarCotizacion")} className={isActive=>(isActive ? "" : "")}>
                    Presupuesto sin compromiso
                </NavLink>
            </button>
        </div>

        <div className="presupuesto">
            <NavLink to="/inicio" onClick={()=>setIdReferencia("solicitarCotizacion")} className={isActive=>(isActive ? "" : "")}>
                Presupuesto sin compromiso
            </NavLink>
        </div>
    </footer>
  )
}

export default Footer