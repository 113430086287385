import "./serviciosinfo.css";
import React from 'react'
import {NavLink} from "react-router-dom";

const ServiciosInfo = ({id,titulo,texto,orientacion,setIdReferencia}) => {
  return (
    <div id={id} className={'cont-servicios-info '+orientacion}>

        {(orientacion==="left") &&
            <>
                <div>
                    <div>
                        <h2>{titulo}</h2>
                        <p>{texto}</p>
                        <button>
                            <NavLink to="/inicio" onClick={()=>setIdReferencia("solicitarCotizacion")} className={isActive=>(isActive ? "" : "")}>
                                Presupuesto sin compromiso
                            </NavLink>
                        </button>
                    </div>
                </div>

                <div className={id}>
                </div>
            </>
        }
        {
            (orientacion==="right") &&
            <>
                <div className={id}>
                </div>
                
                <div >
                    <div>
                            <h2>{titulo}</h2>
                            <p>{texto}</p>
                            <button>
                                <NavLink to="/inicio" onClick={()=>setIdReferencia("solicitarCotizacion")} className={isActive=>(isActive ? "" : "")}>
                                    Presupuesto sin compromiso
                                </NavLink>
                            </button>
                    </div>
                </div>
            </>
        }


    </div>
  )
}

export default ServiciosInfo