import React from 'react';
import "./tarjetasClientes.css";

const TarjetasClientes = ({setImgActiva,objectImg,img,title,texto}) => {
  return (
    <div onClick={()=>setImgActiva(objectImg)} className='clientes'>
        <img src={img} alt={"img_"+img} />
        <h3>{title}</h3>
        <p>{texto}</p>
    </div>
  )
}

export default TarjetasClientes