import "./barraNav.css";
import {useRef} from 'react';
import img from "../../img/Imagen_Sintesis_Cloud.webp"
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars,faCaretDown,faPhoneSquare } from '@fortawesome/free-solid-svg-icons'

const BarraNav = ({setIdReferencia}) => {
    const barraNav = useRef();
    const barraNavLat = useRef();

    const handleOpenBarraLat =()=>{
        if(!barraNav.current.classList.contains("isVisbleMenu")){
            barraNav.current.classList.add("isVisbleMenu");
            barraNavLat.current.classList.add("menuVisible")
        }
        else{
            barraNav.current.classList.remove("isVisbleMenu");
            barraNavLat.current.classList.remove("menuVisible")
        }
    }

  return (
    <div ref={barraNav} className="cont-barraNav">
        <div onClick={handleOpenBarraLat}>
            <FontAwesomeIcon icon={faBars}/>
        </div>
        <NavLink onClick={()=>setIdReferencia("")} to="/inicio" className={isActive=>(isActive ? "" : "")}>
            <img src={img} alt="img_Home_sintesis_cloud"/>
        </NavLink>
        <div ref={barraNavLat}>
            <NavLink onClick={()=>setIdReferencia("")} to="/inicio">Inicio</NavLink>
            <div className="cont-nuestras-app">
                <NavLink onClick={()=>setIdReferencia("")} to="/servicios">
                    <span>Nuestras aplicaciones</span>
                    <FontAwesomeIcon icon={faCaretDown}/>
                    
                </NavLink>
                <div className="subMenuNuestrasApp">
                    <NavLink to="/servicios" className={isActive=>(isActive ? "" : "")}><span onClick={()=>setIdReferencia("id_contabilidad")}><span>Contabilidad</span></span></NavLink>
                    <NavLink to="/servicios" className={isActive=>(isActive ? "" : "")}><span onClick={()=>setIdReferencia("id_inventario")}><span>Inventario</span></span></NavLink>
                    <NavLink to="/servicios" className={isActive=>(isActive ? "" : "")}><span onClick={()=>setIdReferencia("id_gestionCartera")}><span>Cartera</span></span></NavLink>
                    <NavLink to="/servicios" className={isActive=>(isActive ? "" : "")}><span onClick={()=>setIdReferencia("id_facturaElect")}><span>Facturación electrónica</span></span></NavLink>
                    <NavLink to="/servicios" className={isActive=>(isActive ? "" : "")}><span onClick={()=>setIdReferencia("id_nominaElect")}><span>Nomina electrónica</span></span></NavLink>
                    <NavLink to="/servicios" className={isActive=>(isActive ? "" : "")}><span onClick={()=>setIdReferencia("id_financiacion")}><span>Financiación</span></span></NavLink>
                    <NavLink to="/servicios" className={isActive=>(isActive ? "" : "")}><span onClick={()=>setIdReferencia("clientes")}><span>Clientes</span></span></NavLink>
                </div>
            </div>
            <NavLink onClick={()=>setIdReferencia("")} to="/quienesSomos">Quiénes somos</NavLink>
            <NavLink onClick={()=>setIdReferencia("")} to="/contactenos">Contáctenos</NavLink>
        </div>
        <div>
            <FontAwesomeIcon icon={faPhoneSquare}/>
            <span>Llámenos al <a href="tel:+573042541341">(+57) 3042541341</a> </span>
        </div>
        <div>
            <span><a href="tel:+573042541341">Contáctenos</a></span>
        </div>
    </div>
  )
}

export default BarraNav;
