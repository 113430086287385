import './App.css';
import BarraNav from './components/barraNav/BarraNav';
import Home from './components/home/Home';
import Servicios from './components/servicios/Servicios';
import QuienesSomos from './components/quienesSomos/QuienesSomos';
import config from './helpers/config';
import {useEffect, useState} from "react";
import { HashRouter,Routes,Route } from 'react-router-dom';
import { Contactenos } from './components/contactenos/Contactenos';

function App() {
  const [idReferencia,setIdReferencia] = useState("");
  
  useEffect(() => {
    if(!sessionStorage.getItem("clienteActivo")){
      fetch(`${config.host}/nuevaVisita`,{
        method:"POST",
        body: JSON.stringify({}),
        headers:{
          "Content-Type":"application/json"
        }
      })
      .then(res=>res.json())
      .then(res=>{
        // console.log(res)
        sessionStorage.setItem("clienteActivo",true);
      })
    }
  }, []);

  return (
    <div>
        <HashRouter>
          <BarraNav setIdReferencia={setIdReferencia}/>
          <div className='cont-rutas'>
            <Routes>
              <Route path='/' element={<Home setIdReferencia={setIdReferencia} idReferencia={idReferencia}/>}/>
              <Route path='/home' element={<Home setIdReferencia={setIdReferencia} idReferencia={idReferencia}/>}/>
              <Route path='/servicios' element={<Servicios setIdReferencia={setIdReferencia} idReferencia={idReferencia}/>}/>
              <Route path='/quienesSomos' element={<QuienesSomos setIdReferencia={setIdReferencia} idReferencia={idReferencia}/>}/>
              <Route path='/contactenos' element={<Contactenos setIdReferencia={setIdReferencia} idReferencia={idReferencia}/>}/>
              <Route path='*' element={<Home setIdReferencia={setIdReferencia} idReferencia={idReferencia}/>}/>
            </Routes>
          </div>
        </HashRouter>
    </div>
  );
}

export default App;
