import "./home.css";
import video from "../../videos/VideoSintesis.mp4";
import img_contSol from "../../img/img_contSoli.webp";
import galaxyGroup from "../../img/GalaxyGroup.webp";
import maxGroup from "../../img/maxgroup.webp";
import img_sintesis_gris from "../../img/imagen_sintesis_cloud_gris.webp";
import TarjetasServicios from '../tarjetasServicios/TarjetasServicios';
import Footer from "../footer/Footer";
import config from "../../helpers/config"
import {useState,useRef,useEffect} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {faQuoteRightAlt,faCaretDown,faArrowCircleRight,faUserTie,faCircleDollarToSlot,faClipboardList,faBuildingColumns,faEnvelopeCircleCheck,faMoneyBill1} from "@fortawesome/free-solid-svg-icons";

const initInputs={
    nombre:"",
    email:"",
    telefono:"",
    temas:"",
    mensaje:""
}

const Home = ({idReferencia,setIdReferencia}) => {
    const videoHome = useRef();
    const [active,setActive]= useState({});
    const [selectIn,setSelectIn] = useState(false);
    const [servicesSelect,setServicesSelect] = useState([]);
    const [check,setCheck] = useState({});
    const [inputs,setInputs] = useState(initInputs);

    useEffect(()=>{
        window.addEventListener("click",(e)=>{
            if(!e.target.classList.contains("cont-select-serv")
            && !e.target.parentNode.classList.contains("cont-select-serv")
            && !e.target.parentNode.parentNode.classList.contains("cont-select-serv")
            && !e.target.parentNode.parentNode.parentNode.classList.contains("cont-select-serv")
            && !e.target.classList.contains("select-servicios")
            && !e.target.parentNode.classList.contains("select-servicios")
            && !e.target.parentNode.parentNode.classList.contains("select-servicios")
            && !e.target.parentNode.parentNode.parentNode.classList.contains("select-servicios")){
                setSelectIn(false);
            }
        })
    },[])

    useEffect(()=>{
        if(idReferencia !== "" && document.getElementById(idReferencia)){
            let top = document.getElementById(idReferencia).offsetTop;
            window.scroll(0,top-100);
        }
        else{
            window.scroll(0,0);
        }
    },[idReferencia])

    const handleSubmit =(e)=>{
        e.preventDefault();

        if(servicesSelect.toString() ===""){
            alert("Debe seleccionar el servicio en el que esta interesado");
        }
        else{
            let mensaje = `
                El usuario <strong>${inputs.nombre}</strong> ha solitado informacion sobre el tema <strong>${inputs.temas}</strong>,
                <br/><br/>
                el usuario ha proporcionado los siguientes datos para contactarlo:
                <br/><br/>
                <strong>E-mail:</strong> ${inputs.email}.
                <br/><br/>
                <strong>Telefono:</strong> ${inputs.telefono}.
                <br/><br/>
                Ademas a dejado el siguiente mensaje para mas informacion:
                <br/><br/>
                [${inputs.mensaje}]
            `
            fetch(`${config.host}/enviarCorreo`,{
                method:"POST",
                body: JSON.stringify({mensaje:mensaje,subject:`Solicitud de Cotizacion ${inputs.nombre}`}),
                headers:{
                    "Content-Type":"application/json"
                }
            })
           .then(res=>{
                alert("Mensaje enviado con exito.");
                setInputs(initInputs);
                setCheck({});
                setServicesSelect([]);
           })
        }
    }
    const handleSelect =(valor)=>{
        let newSelect;
       if(servicesSelect.indexOf(valor) === -1){
             newSelect = [...servicesSelect,valor]
            setServicesSelect(newSelect);
            setCheck({...check,...{[valor]:true}})
       }
       else{
            newSelect = servicesSelect.filter(el=>el !==valor);
            setServicesSelect(newSelect);
            setCheck({...check,...{[valor]:false}})
       }
       setInputs({...inputs,...{"temas":newSelect.toString()}})
    }

  return (
    <div>
         <div className='cont-video'>
            <video onClick={()=>videoHome.current.play()} ref={videoHome} src={video} width="100%" autoPlay muted controls={false}></video>
        </div>

        <div className='cont-satisfacion'>
            <div>
                <div>
                    <h2>La seguridad y el servicio son siempre nuestras prioridades</h2>
                </div>
                <div>
                    <div className='solicitar-presupuesto'>
                        <NavLink to="/inicio" onClick={()=>setIdReferencia("solicitarCotizacion")} className={isActive=>(isActive ? "" : "")}>
                            <p>Solicite hoy mismo un presupuesto sin compromiso</p>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div>
                <div className='cont-1'>
                    <h3>Satisafacción garantizada</h3>
                    <p>Tratamos cada uno de nuestros clientes con el respeto que se merecen, y no paramos hasta conseguir su máxima satisfacción.</p>
                    <div className='btn-verServicios'><NavLink to="/servicios">Ver todos los servicios</NavLink></div>
                </div>
                <div className='cont-2'>
                    <div>
                        <span className={active.Comprometidos ? "arrow-rotare" : ""} name="Comprometidos" onClick={()=>setActive({"Comprometidos":active.Comprometidos ? !active.Comprometidos : true})}><FontAwesomeIcon icon={faArrowCircleRight}/></span>
                        <span>Comprometidos</span>
                    </div>
                        {
                        active.Comprometidos &&
                        <p>Nustros empleados tienen la capacidad y el conocimiento para encarar todas y cada una de las situaciones, dudas y requerimientos que nuestros clientes necesiten.</p>
                        }
                    <div>
                        <span className={active.Innovadores ? "arrow-rotare" : ""} name="Innovadores" onClick={()=>setActive({"Innovadores":active.Innovadores ? !active.Innovadores : true})}><FontAwesomeIcon icon={faArrowCircleRight}/></span>
                        <span>Innovadores</span>
                    </div>
                        {
                        active.Innovadores &&
                        <p>Nuestro equipo continúa conociendo todas las nuevas prácticas de programación para así poder brindar las últimas tecnologías a nuestros clientes.</p>
                        }
                    <div>
                        <span className={active.Seguridad ? "arrow-rotare" : ""} name="Seguridad" onClick={()=>setActive({"Seguridad":active.Seguridad ? !active.Seguridad : true})}><FontAwesomeIcon icon={faArrowCircleRight}/></span>
                        <span>Seguridad</span>
                    </div>
                        {
                        active.Seguridad &&
                        <p>Sitios completamente seguros para que usted se sienta tranquilo al momento de realizar transacciones de peso dentro de su aplicativo Web</p>
                        }
                </div>
            </div>
        </div>

        <div className='cont-servicios'>
            <div className='cont-title'>
                <h2>Servicios</h2>
            </div>
            <div className='cont-tarjetas'>
                <TarjetasServicios setIdReferencia={setIdReferencia} id="id_contabilidad" icono={faBuildingColumns} titulo="Contabilidad" texto="Información confiable y al instante. Después de Síntesis no pensarás en otro sistema."/>
                <TarjetasServicios setIdReferencia={setIdReferencia} id="id_inventario" icono={faClipboardList} titulo="Inventario" texto="Podrás tener inventarios en tiempo real, compras, vetas, ajustes, traslados entre multiples bodegas, artículos seriados, artículos formulados."/>
                <TarjetasServicios setIdReferencia={setIdReferencia} id="id_gestionCartera" icono={faCircleDollarToSlot} titulo="Cartera" texto="Cartera por edades, gestión de cartera, registro de novedades, registro de bitácora de clientes, informes de cartera, cartera proyectada, pagos, etc.."/>
                <TarjetasServicios setIdReferencia={setIdReferencia} id="id_facturaElect" icono={faEnvelopeCircleCheck} titulo="Facturación electrónica" texto="Ya no más papeles, nuestro sistema envía las facturas a los clientes directamente a su correo y a la dirección de impuestos y aduanas nacionales DIAN."/>
                <TarjetasServicios setIdReferencia={setIdReferencia} id="id_nominaElect" icono={faUserTie} titulo="Nomina electrónica" texto="Con nuestra nómina de manera muy fácil e intuitiva en un solo paso, tendrás la liquidación y la contabilización de todos tus empleados y sin más y cuando lo necesites enviaras a DIAN."/>
                <TarjetasServicios setIdReferencia={setIdReferencia} id="id_financiacion" icono={faMoneyBill1} titulo="Financiación" texto="Nuestro sistema te ofrece un abanico de oportunidades al momento de financiar tus productos y/o servicios financieros. Cálculos a futuro, deuda presente y mucho pero mucho más."/>
            </div>
        </div>

        <div id="solicitarCotizacion" className="cont-solCoti">
            <div className="cont-img">
                <img src={img_contSol} alt="img_solcoti"/>
            </div>

            <div className="cont-form">
                <img src={img_sintesis_gris} alt="img_sintesis_gris"/>
                <h3>¡Solicite hoy mismo una cotización sin compromiso!</h3>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Nombre y appellido</label>
                        <input required value={inputs.nombre} onChange={(e)=>setInputs({...inputs,...{[e.target.name]:e.target.value}})} name="nombre" type="text"/>
                    </div>
                    <div>
                        <label>E-mail</label>
                        <input required value={inputs.email} onChange={(e)=>setInputs({...inputs,...{[e.target.name]:e.target.value}})} name="email" type="text"/>
                    </div>
                    <div>
                        <label>Teléfono</label>
                        <input required value={inputs.telefono} onChange={(e)=>setInputs({...inputs,...{[e.target.name]:e.target.value}})} name="telefono" type="text"/>
                    </div>
                    <div>
                        <label>¿En qué servicio está interesado?</label>
                        <input value={inputs.temas} name="temas" type="hidden"/>
                        <div className="cont-select-serv">
                            <button onClick={()=>setSelectIn(!selectIn)} type="button">
                                <p>{ inputs.temas && inputs.temas  !=="" ? inputs.temas: "Seleccione"}</p>
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </button>

                            {
                                selectIn
                                &&
                            <ul className="select-servicios">
                                <ol>
                                    <label >
                                        <input checked={check.Contabilidad ? true : false} onChange={()=>handleSelect("Contabilidad")} type="checkbox" name="check"/>
                                        Contabilidad
                                    </label>
                                </ol>
                                <ol>
                                    <label >
                                        <input checked={check.Inventario ? true : false} onChange={()=>handleSelect("Inventario")} type="checkbox" name="check"/>
                                        Inventario
                                    </label>
                                </ol>
                                <ol>
                                    <label >
                                        <input checked={check.Cartera ? true : false} onChange={()=>handleSelect("Cartera")} type="checkbox" name="check" />
                                        Cartera
                                    </label>
                                </ol>
                                <ol>
                                    <label >
                                        <input checked={check["Facturación electrónica"] ? true : false} onChange={()=>handleSelect("Facturación electrónica")} type="checkbox" name="check" />
                                        Facturación electrónica
                                    </label>
                                </ol>
                                <ol>
                                    <label >
                                        <input checked={check["Nomina electrónica"] ? true : false} onChange={()=>handleSelect("Nomina electrónica")} type="checkbox" name="check"/>
                                        Nomina electrónica
                                    </label>
                                </ol>
                                <ol>
                                    <label >
                                        <input checked={check.Financiación ? true : false} onChange={()=>handleSelect("Financiación")} type="checkbox" name="check" />
                                        Financiación
                                    </label>
                                </ol>
                                <ol>
                                    <label >
                                        <input checked={check.Otro ? true : false} onChange={()=>handleSelect("Otro")} type="checkbox" name="check"/>
                                        Otro
                                    </label>
                                </ol>
                            </ul>
                            }
                        </div>
                    </div>
                    <div className="cont-mensaje">
                        <label>Mensaje</label>
                        <textarea required value={inputs.mensaje} onChange={(e)=>setInputs({...inputs,...{[e.target.name]:e.target.value}})} name="mensaje" cols="30" rows="10"></textarea>
                    </div>
                    <div className="cont-enviar">
                        <button type="submit">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
        
        <div className="cont-testimonios">
            <div>
                <h2>Testimonios</h2>
            </div>
            <div>
                <div className="testimonio">
                    <img src={galaxyGroup} alt="galaxyGroup"/>
                    <div>
                        <FontAwesomeIcon icon={faQuoteRightAlt}/>
                        <h3>LUIS GUILLERMO.</h3>
                        <p>Tuve muchos sistemas antes de Síntesis y me di cuenta que había perdido mucho tiempo, gracias Síntesis.</p>
                    </div>
                </div>
                
                <div className="testimonio">
                    <img src={maxGroup} alt="maxGroup"/>
                    <div>
                        <FontAwesomeIcon icon={faQuoteRightAlt}/>
                        <h3>BERNARDO SAN JUAN.</h3>
                        <p>El soporte es oportuno, siempre encuentro una respuesta amable al momento de consultar.</p>
                    </div>
                </div>
            </div>
        </div>

        <Footer setIdReferencia={setIdReferencia}/>

    </div>
  )
}

export default Home