import "./quienesSomos.css";
import img_QS_2 from "../../img/img_QS-2.webp";
import Footer from "../footer/Footer";
import {useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClockFour,faUserCircle,faAddressBook,faThumbsUp} from "@fortawesome/free-regular-svg-icons"

const QuienesSomos = ({idReferencia,setIdReferencia}) => {

    useEffect(()=>{
        if(idReferencia !== "" && document.getElementById(idReferencia)){
            let top = document.getElementById(idReferencia).offsetTop;
            window.scroll(0,top-100);
        }
        else{
            window.scroll(0,0);
        }
    },[idReferencia])

  return (
    <div className='cont-quienes'>
        <div>
            <h2>Quiénes somos</h2>
        </div>

        <div>
            <img src={img_QS_2} alt=""/>
            <div>
                <p>En el año 2015 empezó la idea de crear un sistema que fuera para todos y que todos pudieran manejar, que fuera tan sencillo para un negocio pequeño, pero tan robusto para una empresa mediana.</p>
                <p>Se quería un sistema que no dependiera de un sistema operativo, que no dependiera de un servidor puesto en una empresa, que se pudiera manejar desde cualquier lugar del mundo, desde un PC, Portátil, tablet o hasta un Smartphone.</p>
                <br/>
                <p>Gracias a las tecnologías a nuestro alcance, se fue desarrollando un producto amigable y funcional.</p>
                <br/>
                <p>En el año 2020 se crea Síntesis Cloud con muchas expectativas que a lo largo de este tiempo se han venido cumpliendo.</p>
                <p>Hoy en día contamos con un equipo de talento humano sin precedente y unos directivos que sin duda hace todo lo posible para que este proyecto se mantenga a flote y siga cumpliendo su principal objetivo:</p>
                <br/>
                <p>Tener clientes felices.</p>
                <button>Contactenos</button>
            </div>
        </div>

        <div>
            <div>
                <FontAwesomeIcon icon={faClockFour} />
                <span>Trabajo continuo</span>
            </div>
            <div>
                <FontAwesomeIcon icon={faUserCircle} />
                <span>Clientes satisfechos</span>
            </div>
            <div>
                <FontAwesomeIcon icon={faAddressBook} />
                <span>Proyectos terminados</span>
            </div>
            <div>
                <FontAwesomeIcon icon={faThumbsUp} />
                <span>Premios ganados</span>
            </div>

        </div>
        
        <Footer setIdReferencia={setIdReferencia}/>
    </div>
  )
}

export default QuienesSomos