import "./tarjetasSerivicios.css";
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

const TarjetasServicios = ({id,icono,titulo,texto,setIdReferencia}) => {
  return (
    <div className='tarjeta'>
        <FontAwesomeIcon icon={icono}/>
        <h2>{titulo}</h2>
        <p>{texto}</p>
        <NavLink to="/servicios" onClick={()=>setIdReferencia(id)}>Leer mas</NavLink>
    </div>
  )
}

export default TarjetasServicios